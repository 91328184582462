$(document).ready(function() {
    $('#dataTables-example').DataTable({
            responsive: true
    });

    $('.confirm').confirm();

    $( ".datepicker" ).datepicker({dateFormat: 'yy-mm-dd'});

    $('.remove_category_image').click(function(){
		$.ajax({
			type: "POST",
			url: "/admin/categories/remove-image",
			headers: { 'X-CSRF-TOKEN': $('input[name="_token"]').val() },
			data: {
				id : $(this).attr('data-category')
			}
        }).done(function(data) {
      		if (data.success) {
      			$('.page_image_div').fadeOut();
      		}
        });
	});

	$('.remove_machine_image').click(function(){
		$.ajax({
			type: "POST",
			url: "/admin/machines/remove-image",
			headers: { 'X-CSRF-TOKEN': $('input[name="_token"]').val() },
			data: {
				id : $(this).attr('data-category'),
				number : $(this).attr('data-number'),
				folder : $(this).attr('data-folder')
			}
        }).done(function(data) {
      		if (data.success) {
      		}
        });
        $(this).parent().fadeOut();
	});

	$('.remove_brand_image').click(function(){
		$.ajax({
			type: "POST",
			url: "/admin/brands/remove-image",
			headers: { 'X-CSRF-TOKEN': $('input[name="_token"]').val() },
			data: {
				id : $(this).attr('data-brand')
			}
        }).done(function(data) {
      		if (data.success) {
      			$('.page_image_div').fadeOut();
      		}
        });
	});

	$('.remove_staff_image').click(function(){
		$.ajax({
			type: "POST",
			url: "/admin/staff/remove-image",
			headers: { 'X-CSRF-TOKEN': $('input[name="_token"]').val() },
			data: {
				id : $(this).attr('data-staff')
			}
        }).done(function(data) {
      		if (data.success) {
      			$('.staff_image_div').fadeOut();
      		}
        });
	});

	$('.remove_service_image').click(function(){
		$.ajax({
			type: "POST",
			url: "/admin/services/remove-image",
			headers: { 'X-CSRF-TOKEN': $('input[name="_token"]').val() },
			data: {
				id : $(this).attr('data-service')
			}
        }).done(function(data) {
      		if (data.success) {
      			$('.service_image_div').fadeOut();
      		}
        });
	});

	$('.remove_popup_image').click(function(){
		$.ajax({
			type: "POST",
			url: "/admin/popups/remove-image",
			headers: { 'X-CSRF-TOKEN': $('input[name="_token"]').val() },
			data: {
				id : $(this).attr('data-popup')
			}
        }).done(function(data) {
      		if (data.success) {
      			$('.popup_image_div').fadeOut();
      		}
        });
	});

	$('.remove_popup_pdf').click(function(){
		$.ajax({
			type: "POST",
			url: "/admin/popups/remove-pdf",
			headers: { 'X-CSRF-TOKEN': $('input[name="_token"]').val() },
			data: {
				id : $(this).attr('data-category'),
				number : $(this).attr('data-number'),
				folder : $(this).attr('data-folder')
			}
        }).done(function(data) {
      		if (data.success) {
      		}
        });
        $(this).parent().fadeOut();
	});

	$('.remove_brand_pdf').click(function(){
		$.ajax({
			type: "POST",
			url: "/admin/brands/remove-pdf",
			headers: { 'X-CSRF-TOKEN': $('input[name="_token"]').val() },
			data: {
				id : $(this).attr('data-brand'),
				pdf : $(this).attr('data-pdf'),
			}
        }).done(function(data) {
      		if (data.success) {
      		}
        });
        $(this).parent().fadeOut();
	});

    $('.dateTimePicker').datetimepicker(
      {
        format: 'YYYY-MM-DD HH:mm:ss'
      }
    );
});
